import Banner from "../../components/banner";
import { TiGroup } from "react-icons/ti";
import { BsPersonPlus, BsFillCalendar2EventFill } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Select, { components } from "react-select";
import { FaPlus, FaDollarSign } from "react-icons/fa";
import { useEffect, useState } from "react";
import { FaApple, FaCar, FaCoffee, FaUtensils } from "react-icons/fa";
import { json, Link, useNavigate, useParams } from "react-router-dom";
import { useEventContext } from "../../contexts/EventContextProvider";
import { baseUrl } from "../../apis/base_url";
import axios from "axios";

import { ColorRing } from "react-loader-spinner";
import Success from "./SuccessFull";
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { errorToast, infoToast } from "../../utils/utilityFunctions";

const OurRetreatPlans = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  console.log("id", id);
  const [selectedOption, setSelectedOption] = useState(null);
  const [hidePlans, setHidePlans] = useState(true);
  const { getEventById, event } = useEventContext();
  const { userProfile} = useAuthContext();
  const position = [31.505, 30.09];
  const [loading, setLoading] = useState(false);
  const [token, settoken] = useState(null);
  const [options, setOptions] = useState([
    {
      label: "Choose Your Hotel",
      value: "Choose Your Hotel",
      price: 0,
    },
  ]);
  const event_id = event?.accomodation[0]?.event_id;
  const plan_id = event?.accomodation[0]?.id;

  const getAllEvents = async () => {
    setLoading(true);
    await getEventById(id);
    setLoading(false);
  };

  useEffect(() => {
    getAllEvents();
  }, [id]);

  useEffect(()=>{
    console.log("userProfile :", userProfile)
  },[])

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
  
  // console.log("Events :", event)

  const updateOptions = () => {
    let newOptions =
      event &&
      event["accomodation"] &&
      event["accomodation"].map((item, index) => {
        return {
          value: index,
          label: (
            <div className="flex justify-between px-1 py-1 w-full text-[#505D68]">
              <div className="flex flex-row items-end">
                <span className="text-base font-bold">
                  {item.no_of_persons} Person
                </span>
                <span className="text-[8px] ">({item.type})</span>
              </div>
              <div className="flex items-end ">
                <FaPlus size={10} className="text-sm text-customGreen" />
                <span className="-mb-1 text-base font-bold">{item.price}</span>
                <FaDollarSign size={10} className="text-lg" />
              </div>
            </div>
          ),
          price: item.price,
        };
      });
    event &&
      event["accomodation"] &&
      setOptions([
        {
          label: "Choose Your Hotel",
          value: "Choose Your Hotel",
          price: 0,
        },
        ...newOptions,
      ]);
  };

  useEffect(() => {
    updateOptions();
  }, [event]);

  useEffect(() => {
    const storeToken = localStorage.getItem("token");
    if (storeToken) {
      settoken(JSON.parse(storeToken));
    }
  }, []);
  
  const Buyfuntion = async () => {
    try {
      const event_id = event?.accomodation[0]?.event_id;
      const plan_id = event?.accomodation[0]?.id;
      // const event_id = eventId;
      // const plan_id = planId;

      if (!event_id || !plan_id) {
        console.error("Event ID or Plan ID is missing");
        errorToast("Something went wrong!");
        return;
      }

      // Store eventId and planId in localStorage
      localStorage.setItem("eventId", event_id);
      localStorage.setItem("planId", plan_id);

      const json_objec = {
        event_id: event_id,
        plan_id: plan_id,
      };

      if(userProfile.phone !== null){
        let response = await axios.post(
          "https://api.sunkissyogi.com/api/v1/payment",
          json_objec
        );
  
        if (response && response.data.success) {
          const checkoutUrl = response.data.data.url;
          if (checkoutUrl) {
            console.log("Redirecting to Stripe checkout:", checkoutUrl);
            window.location.href = checkoutUrl;
          } else {
            console.error("Checkout URL is missing from the response");
          }
        } else {
          console.error(
            "Payment failed or success flag is not true",
            response.data
          );
        }
      }else if(userProfile.phone === null){
        navigate('/profile/edit', {state: {from: window.location.pathname}});
        infoToast("Add phone number!")
      }

      
    } catch (error) {
      console.error(
        "Error occurred:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <>
      {!loading ? (
        event && (
          <section>
            <Banner
              image={
                baseUrl +
                "/" +
                event.event.banner_image.path +
                "/" +
                event.event.banner_image.name
              }
              title="Our Retreat Plans"
            />
            {/* section 1 */}
            <div className="lg:w-[80%] w-full lg:p-0 px-4 mt-16 mx-auto ">
              <div className="flex flex-col gap-5 lg:flex-row">
                {/* images */}
                <div className="lg:w-[75%] w-full">
                  <h3 className="text-lg font-normal">
                    {event["event"].category.name}
                  </h3>
                  <h1 className="text-4xl font-bold md:font-extrabold md:text-2xl mb-7">
                    {event["event"].title}
                  </h1>
                  <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 md:grid-cols-2">
                    {event["extra_images"].map((item, index) => {
                      return (
                        <>
                          {item && (
                            <img
                              key={index}
                              loading="lazy"
                              className={`w-full object-cover h-[17rem] ${
                                index == 3 && "lg:col-span-2"
                              } object-center rounded-md`}
                              src={baseUrl + "/" + item.path + "/" + item.name}
                              alt="place"
                            />
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
                {/* price card and map */}
                <div className="lg:w-[25%] w-full ">
                  <div className="shadow-xl rounded-xl mt-[89px]">
                    <div className="p-6  rounded-md">
                      <div className="flex ">
                        <p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-share-3"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#00b341"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M13 4v4c-6.575 1.028 -9.02 6.788 -10 12c-.037 .206 5.384 -5.962 10 -6v4l8 -7l-8 -7z" />
                          </svg>
                        </p>
                        <h1 className="font-semibold text-xl ml-4">
                          Share this listing
                        </h1>
                      </div>
                      <div className="flex p-2">
                        <Link to={"https://www.facebook.com/"}>
                          <div className="flex border rounded w-24 ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-brand-facebook"
                              width="32"
                              height="32"
                              viewBox="0 0 24 24"
                              stroke-width="1"
                              stroke="#00abfb"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                            </svg>
                            <p className="mt-1">Facebook</p>
                          </div>
                        </Link>
                        <Link>
                          <div className="flex border rounded w-24 ml-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-brand-x"
                              width="32"
                              height="32"
                              viewBox="0 0 24 24"
                              stroke-width="1"
                              stroke="#000000"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M4 4l11.733 16h4.267l-11.733 -16z" />
                              <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" />
                            </svg>
                            <p className="mt-1">Twitter</p>
                          </div>
                        </Link>
                      </div>
                      <div className="flex p-2">
                        <Link to={"https://mail.google.com/mail"}>
                          <div className="flex border rounded w-24 p-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-mail"
                              width="25"
                              height="25"
                              viewBox="0 0 24 24"
                              stroke-width="1"
                              stroke="#00b341"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                              <path d="M3 7l9 6l9 -6" />
                            </svg>
                            <p>Email</p>
                          </div>
                        </Link>
                        <Link to={"https://web.whatsapp.com/"}>
                          <div className="flex border rounded w-24 ml-2">
                            <svg
                              height="33px"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16 31C23.732 31 30 24.732 30 17C30 9.26801 23.732 3 16 3C8.26801 3 2 9.26801 2 17C2 19.5109 2.661 21.8674 3.81847 23.905L2 31L9.31486 29.3038C11.3014 30.3854 13.5789 31 16 31ZM16 28.8462C22.5425 28.8462 27.8462 23.5425 27.8462 17C27.8462 10.4576 22.5425 5.15385 16 5.15385C9.45755 5.15385 4.15385 10.4576 4.15385 17C4.15385 19.5261 4.9445 21.8675 6.29184 23.7902L5.23077 27.7692L9.27993 26.7569C11.1894 28.0746 13.5046 28.8462 16 28.8462Z"
                                fill="#BFC8D0"
                              />
                              <path
                                d="M28 16C28 22.6274 22.6274 28 16 28C13.4722 28 11.1269 27.2184 9.19266 25.8837L5.09091 26.9091L6.16576 22.8784C4.80092 20.9307 4 18.5589 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
                                fill="url(#paint0_linear_87_7264)"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 18.5109 2.661 20.8674 3.81847 22.905L2 30L9.31486 28.3038C11.3014 29.3854 13.5789 30 16 30ZM16 27.8462C22.5425 27.8462 27.8462 22.5425 27.8462 16C27.8462 9.45755 22.5425 4.15385 16 4.15385C9.45755 4.15385 4.15385 9.45755 4.15385 16C4.15385 18.5261 4.9445 20.8675 6.29184 22.7902L5.23077 26.7692L9.27993 25.7569C11.1894 27.0746 13.5046 27.8462 16 27.8462Z"
                                fill="white"
                              />
                              <path
                                d="M12.5 9.49989C12.1672 8.83131 11.6565 8.8905 11.1407 8.8905C10.2188 8.8905 8.78125 9.99478 8.78125 12.05C8.78125 13.7343 9.52345 15.578 12.0244 18.3361C14.438 20.9979 17.6094 22.3748 20.2422 22.3279C22.875 22.2811 23.4167 20.0154 23.4167 19.2503C23.4167 18.9112 23.2062 18.742 23.0613 18.696C22.1641 18.2654 20.5093 17.4631 20.1328 17.3124C19.7563 17.1617 19.5597 17.3656 19.4375 17.4765C19.0961 17.8018 18.4193 18.7608 18.1875 18.9765C17.9558 19.1922 17.6103 19.083 17.4665 19.0015C16.9374 18.7892 15.5029 18.1511 14.3595 17.0426C12.9453 15.6718 12.8623 15.2001 12.5959 14.7803C12.3828 14.4444 12.5392 14.2384 12.6172 14.1483C12.9219 13.7968 13.3426 13.254 13.5313 12.9843C13.7199 12.7145 13.5702 12.305 13.4803 12.05C13.0938 10.953 12.7663 10.0347 12.5 9.49989Z"
                                fill="white"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_87_7264"
                                  x1={26.5}
                                  y1={7}
                                  x2={4}
                                  y2={28}
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#5BD066" />
                                  <stop offset={1} stopColor="#27B43E" />
                                </linearGradient>
                              </defs>
                            </svg>
                            <p className="mt-1">Whatsapp</p>
                          </div>
                        </Link>
                      </div>
                      <div>
                        <Link to={"https://github.com/"}>
                          <div className="flex border rounded w-24 ml-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-link"
                              width="32"
                              height="32"
                              viewBox="0 0 24 24"
                              stroke-width="1"
                              stroke="#00b341"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M9 15l6 -6" />
                              <path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464" />
                              <path d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463" />
                            </svg>

                            <p className="mt-1">Get</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="shadow-xl rounded-xl mt-6">
                    {/* Header */}
                    <div className="flex items-center justify-between mb-4 bg-customGreen rounded-t-xl">
                      <div className="flex items-center justify-around w-[90%] mx-auto my-3 ">
                        <h3 className="font-bold text-white">Date</h3>
                        <div className="flex items-center gap-3">
                          <BsFillCalendar2EventFill color="white" />
                          <span className="text-white">
                            {event["event"].date_at
                              .split("-")[0]
                              .split(",")[1]
                              .substr(0, 3)}{" "}
                            -{" "}
                            {event["event"].date_at
                              .split("-")[1]
                              .split(",")[1]
                              .substr(0, 3)}
                          </span>
                        </div>
                      </div>
                    </div>
                    {
                      event && event?.purchased === 1 ? <h3 className="text-center pb-5 px-3 font-semibold">Package already purchased</h3>: <div className="p-3">
                      {/* duration */}
                      <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center justify-center bg-white rounded-full ">
                          <RxDotFilled />
                          <h3 className="font-bold text-md">Duration</h3>
                        </div>
                        <span className="ml-2">
                          {event["event"].duration} days
                        </span>
                      </div>
                      <div className="justify-items-end">
                        <span className="ml-48 font-bold text-md  ">
                          ${" "}
                          {selectedOption
                            ? parseInt(event["event"].price) +
                              parseInt(selectedOption.price)
                            : event["event"].price}
                        </span>
                      </div>

                      {/* accomodation */}
                      <div className="flex items-center mb-2">
                        <div className="flex items-center justify-center bg-white rounded-full ">
                          <RxDotFilled />
                          <h3 className="font-bold text-md">Accomodation</h3>
                        </div>
                      </div>
                      <span className="ml-2 text-sm">
                        Which type of room you want?
                      </span>
                      {/* select */}
                      <div className="flex items-center justify-between w-full">
                        <Select
                          options={options}
                          isSearchable={false}
                          placeholder="Choose Your Hotel"
                          value={selectedOption}
                          onChange={(option) => {
                            console.log(option);
                            setSelectedOption(option);
                          }}
                          styles={customStyles}
                          menuPortalTarget={document.body}
                        />
                      </div>

                      {/* Group Coordinator */}
                      <div className="flex flex-col items-start mt-2">
                        <div className="flex items-center justify-center bg-white rounded-full ">
                          <RxDotFilled />
                          <h3 className="font-bold text-md">
                            Group Co-Ordinator
                          </h3>
                        </div>
                        <div className="grid w-full grid-cols-2 gap-2 ms-5">
                          <span>Hasnat</span>
                          <span>Asad</span>
                        </div>
                      </div>
                      {/* Total Group Members */}
                      <div className="flex items-center justify-between w-full mt-2 mb-2">
                        <div className="flex items-center justify-center bg-white rounded-full ">
                          <RxDotFilled />
                          <h3 className="font-bold text-md">
                            Total Group Members
                          </h3>
                        </div>
                        <span className="text-black">
                          {event["event"].total_group_members}
                        </span>
                      </div>
                      {/* line */}
                      <hr className=" border-black border-[2px] my-3"></hr>

                      {/* Total Price */}

                      <div className="flex justify-between text-black">
                        <span>Total Price</span>
                        <span className="font-bold text-md">
                          ${" "}
                          {selectedOption
                            ? parseInt(event["event"].price) +
                              parseInt(selectedOption.price)
                            : event["event"].price}
                        </span>
                      </div>
                      {/* Book It Now */}
                   
                      {token ? (
                        <button
                          onClick={Buyfuntion}
                          className="w-full px-4 py-2 mt-3 text-white rounded-md bg-customGreen"
                        >
                          Book It Now
                        </button>
                      ) : (
                        <Link to={"/login"}>
                          <button className="w-full px-4 py-2 mt-3 text-white rounded-md bg-customGreen">
                            Book It Now
                          </button>
                        </Link>
                      )}
                         <Link to={`/retreat-plans/${id}/financing`}>
                      <button className="w-full px-4 py-2 mt-3 text-white rounded-md bg-customGreen">
                            Financing
                      </button>
                      </Link>
                    </div>
                    }

                    {/* Card Details */}

                   
                  </div>

                  {/* map */}
                  <div className="mt-8 border rounded-md outline-none border-customGray">
                    {event && event["event"] && event["event"].location && (
                      <iframe
                      src={`https://www.google.com/maps?q=${event["event"]?.coordinates?.latitude},${event["event"]?.coordinates?.longitude}&hl=es;z=14&output=embed`}
                        className="h-full w-full rounded-[8px]"
                      ></iframe>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* what's included */}
            <div className="lg:w-[80%] w-full lg:p-0 px-4 mt-16 mx-auto ">
              <div className="text-center">
                <h2 className="mb-5 text-2xl font-bold md:text-5xl">
                  What's Included
                </h2>
                <div className="grid grid-cols-4 gap-5 md:grid-cols-2 lg:grid-cols-4">
                  {event["features"].map((item, index) => (
                    <div
                      key={index}
                      className={`p-5  rounded-lg shadow-lg hover:shadow-lg ${
                        item.colSpan === 2 ? "lg:col-span-2" : ""
                      }`}
                    >
                      <div className="flex justify-center mb-3">
                        <img src={baseUrl + "/" + item.icon.path} alt="Icon" />
                      </div>
                      <div
                        className="mb-1 text-xl font-extrabold aligned-text text-start"
                        style={{
                          fontFamily: '"Roboto Slab", serif',
                          fontOpticalSizing: "auto",
                        }}
                      >
                        {item.title}
                      </div>
                      <div className="overflow-hidden text-sm leading-tight text-left text-gray-700"
                     style={{
                      fontFamily: '"Lora", serif', 
                      fontOpticalSizing: 'auto', 
                      fontWeight: '500', 
                      fontStyle: 'normal', 
                    }}
                      >
                        {item.description}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Itinerary */}
            <div className="lg:w-[60%] md:w-[80%] w-full lg:p-0 px-4 mt-16 mx-auto ">
              <div className="text-center">
                <h2 className="mb-5 text-2xl font-bold md:text-5xl">
                  Itinerary
                </h2>
                <h3 className="text-4xl font-bold md:w-[70%] w-full mx-auto text-center text-[#505D68]">
                  {event["event"]["date_at"]}
                </h3>
                {event["plan"].map((day, index) => (
                  <div
                    key={index}
                    className={`mb-12 ${
                      index > 1 && hidePlans ? "hidden" : "flex flex-col"
                    }`}
                  >
                    <h2 className="mt-10 text-2xl font-bold text-left md:text-3xl ">{`Day ${
                      index + 1
                    }: ${day.day_date}`}</h2>
                    <div className="grid grid-cols-1 gap-5 mt-5">
                      <div className="ps-5">
                        {day.morning && day.morning !== "" && (
                          <>
                            {" "}
                            <div className="text-md text-left font-bold text-[#505D68] mb-1">
                              Morning
                            </div>
                            <ul className="text-sm text-left text-gray-700 ms-2">
                              {day.morning.split(",").map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          </>
                        )}
                        {day.lunch_break && day.lunch_break !== "" && (
                          <>
                            {" "}
                            <div className="text-md text-left font-bold text-[#505D68] mb-1">
                              Lunch Break
                            </div>
                            <ul className="text-sm text-left text-gray-700 ms-2">
                              {day.lunch_break.split(",").map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          </>
                        )}
                        {day.afternoon && day.afternoon !== "" && (
                          <>
                            {" "}
                            <div className="text-md text-left font-bold text-[#505D68] mb-1">
                              Afternoon
                            </div>
                            <ul className="text-sm text-left text-gray-700 ms-2">
                              {day.afternoon.split(",").map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          </>
                        )}

                        {day.evening && day.evening !== "" && (
                          <>
                            {" "}
                            <div className="text-md text-left font-bold text-[#505D68] mb-1">
                              Evening
                            </div>
                            <ul className="text-sm text-left text-gray-700 ms-2">
                              {day.evening.split(",").map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          </>
                        )}
                        {day.dinner && day.dinner !== "" && (
                          <>
                            {" "}
                            <div className="text-md text-left font-bold text-[#505D68] mb-1">
                              Dinner
                            </div>
                            <ul className="text-sm text-left text-gray-700 ms-2">
                              {day.dinner.split(",").map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          </>
                        )}
                        {day.night_time && day.night_time !== "" && (
                          <>
                            {" "}
                            <div className="text-md text-left font-bold text-[#505D68] mb-1">
                              Night Time
                            </div>
                            <ul className="text-sm text-left text-gray-700 ms-2">
                              {day.night_time.split(",").map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* see more */}
            {event["plan"].length > 1 && (
              <div className="lg:w-[80%] w-full lg:p-0 px-4 mt-16 mx-auto ">
                <button
                  onClick={() => setHidePlans(!hidePlans)}
                  className="mt-10 border py-2 border-[#505D68] rounded-md w-full text-[#505D68] text-md shadow-md shadow-[#505D68]"
                >
                  {hidePlans ? "Expand All Plans" : "Hide All Plans"}
                </button>
              </div>
            )}

            <div className="flex items-center justify-center w-full mt-7">
              <Link to="/retreat-plans">
                <button className="px-10 py-2 text-sm font-normal text-white bg-[#8DC67B] rounded-md shadow-md hover:text-white gradient-bg">
                  See More Retreats
                </button>
              </Link>
            </div>
          </section>
        )
      ) : (
        <div className="flex items-center justify-center w-full my-20 text-center">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["black"]}
          />
        </div>
      )}
    </>
  );
};

export default OurRetreatPlans;
