// EventContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader } from "../apis/header";

const IconsContext = createContext();

export const IconsContextProvider = ({ children }) => {
    const [allIcons, setAllIcons] = useState(null);

    const getIcons = async (token) => {
        try {
            const headers = jsonHeader(token)

            const response = await ApiTemplate("get", "/api/v1/icons", {}, headers);
            if (response && response["success"] === true) {
                console.log(response["data"]);
                setAllIcons(response["data"]);
            } else {
                console.log(response["message"], response.message);
            }
        } catch (error) {
            console.error("Error during API call:", error);
        }
    };

    const addIcon = async (token, eventData) => {
        try {
            const headers = {
                "Content-Type": "multipart/form-data;",
                "X-CSRF-TOKEN": document.head
                    .querySelector('meta[name="csrf-token"]')
                    ?.getAttribute("content"),
                Authorization: `Bearer ${token}`,
            };

            const response = await ApiTemplate("post", "/api/v1/event", eventData, headers);
            if (response && response["success"] === true) {
                console.log(response["data"]);
                // setAllCategories([...allCategories, response["data"]]);
                // setAddEventId(response["data"]["id"])
                return true;
            } else {
                console.log(response["message"], response.message);
                return false;

            }
        } catch (error) {
            console.error("Error during API call:", error);
            return false;

        }
    };


    const deleteEvent = async (token, eventId) => {
        try {
            // Implement the logic to delete an event using your API here
        } catch (error) {
            console.error("Error during API call:", error);
        }
    };

    const updateEvent = async (token, eventId, eventData) => {
        try {
            // Implement the logic to update an event using your API here
        } catch (error) {
            console.error("Error during API call:", error);
        }
    };

    return (
        <IconsContext.Provider
            value={{
                allIcons,
                getIcons,
                addIcon,

                deleteEvent,
                updateEvent,

            }}
        >
            {children}
        </IconsContext.Provider>
    );
};

export const useIconsContext = () => {
    const iconsContextValue = useContext(IconsContext);
    return iconsContextValue;
};
