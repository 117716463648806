// ContactSupportContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader, jsonHeaderWithoutToken, multiFormHeader } from "../apis/header";

const ContactSupportContext = createContext();

export const ContactSupportContextProvider = ({ children }) => {

  const addAllQueries = async (blogData) => {
    try {
      const headers = jsonHeaderWithoutToken()

      const response = await ApiTemplate("post", "/api/v1/customer/support", blogData, headers);
     return response;
    } catch (error) {
      console.error("Error during API call:", error);
      return {
        success:false,
        message: "Something went wrong.please try again.",
      };

    }
  };


  return (
    <ContactSupportContext.Provider
      value={{
        addAllQueries,
      }}
    >
      {children}
    </ContactSupportContext.Provider>
  );
};

export const useContactSupportContext = () => {
  const blogsContextValue = useContext(ContactSupportContext);
  return blogsContextValue;
};
