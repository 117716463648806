import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import { useAuthContext } from '../../contexts/AuthContextProvider';

const ForgotPassword = () => {
  const [email, setEmail]= useState(null)
  const [emailSend, setEmailSend] = useState(false);
  const navigate = useNavigate();
  const {passwordReset} = useAuthContext();

  const handleSubmit = async () => {
    setEmailSend(true)
      await passwordReset({email:email});
      setEmailSend(false)
      navigate('/verify-code'); // Navigate to VerifyCode screen
    
  };

  return (
    <section className="w-full py-10">
      <div className="text-black flex gap-4 flex-col md:w-[23rem] mx-auto">
        <span className="flex gap-2 cursor-pointer" onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack size={25} /> Back to login
        </span>
        <p className="text-2xl text-center">Forgot Password</p>
        {/* Sending email to reset password */}
        <div className="flex flex-col gap-2">
          <div className="mb-3">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-black rounded-md"
            />
          </div>
          <button
            onClick={handleSubmit}
            className="bg-[#8DC67B] text-white text-mm py-2 px-4 rounded-md w-full"
          >
            {emailSend ? "Send Email..." : "Send Email"}
          </button>
        </div>
      </div>
    </section>
  );
};

const VerifyCode = () => {
  const [code, setCode] = useState('');
  const [resendTimer, setResendTimer] = useState(30);
  const navigate = useNavigate();
  const {confirmationCode} = useAuthContext();


  const handleSubmit =async () => {
    await confirmationCode({code:code})
    navigate('/create-new-password', { state: { code } });
    // Add API call or verification logic here
  };

  const handleResendCode = () => {
    console.log('Code resent');
    setResendTimer(30); // Reset the timer
    // Add API call for resending the code
  };

  React.useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer]);

  return (
    <section className="w-full py-10">
      <div className="text-black flex gap-4 flex-col md:w-[23rem] mx-auto">
        <span className="flex gap-2 cursor-pointer" onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack size={25} /> Back
        </span>
        <p className="text-2xl text-center">Verify Your Email</p>
        <p className="text-center">Please enter the code sent to your email.</p>
        <div className="flex flex-col gap-2">
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter verification code"
            maxLength={6}
            className="w-full px-3 py-2 border border-black rounded-md text-center"
          />
          <button
            onClick={handleSubmit}
            className="bg-[#8DC67B] text-white text-mm py-2 px-4 rounded-md w-full"
            disabled={!code}
          >
            Submit
          </button>
          <div className="text-center mt-4">
            {resendTimer > 0 ? (
              <p>Resend code in {resendTimer}s</p>
            ) : (
              <button
                onClick={handleResendCode}
                className="text-[#8DC67B] underline"
              >
                Resend Code
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const CreateNewPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const {confirmNewPassword} = useAuthContext();

  // Access the state passed during navigation
  const code = location.state?.code;

  const handleSubmit = async () => {
    if (newPassword === confirmPassword) {
      await confirmNewPassword({code, password:newPassword, password_confirmation:confirmPassword});
      // Add API call for password reset here
      navigate('/login'); // Navigate back to login
    } else {
      alert("Passwords do not match!");
    }
  };

  return (
    <section className="w-full py-10">
      <div className="text-black flex gap-4 flex-col md:w-[23rem] mx-auto">
        <span className="flex gap-2 cursor-pointer" onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack size={25} /> Back
        </span>
        <p className="text-2xl text-center">Create New Password</p>
        <div className="flex flex-col gap-2">
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="w-full px-3 py-2 border border-black rounded-md"
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full px-3 py-2 border border-black rounded-md"
          />
          <button
            onClick={handleSubmit}
            className="bg-[#8DC67B] text-white text-mm py-2 px-4 rounded-md w-full"
            disabled={!newPassword || !confirmPassword}
          >
            Reset Password
          </button>
        </div>
      </div>
    </section>
  );
};

export { ForgotPassword, VerifyCode, CreateNewPassword };