import './App.css';
import { Route, Routes } from 'react-router-dom';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useAuthContext } from './contexts/AuthContextProvider';
import { useNavigate, Navigate } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner'

// Direct imports for Home and Layout components
import Home from './pages/home';
import Layout from './pages/layout';
import OurRetreatPlans from './pages/our_retreat_plans';
import PrivateRoutes from './pages/privateRoutes';
import FavoriteEvents from './pages/favorite/favorite';
import {  VerifyCode, CreateNewPassword } from './pages/forgotpassword/ForgotPassword';
import { ForgotPassword } from './pages/forgotpassword/ForgotPassword';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Lazy load other components
const Contact = lazy(() => import('./pages/contact'));
const PrivacyPolicy = lazy(() => import('./pages/privacy-policy'));
const NoPage = lazy(() => import('./pages/404'));
const AboutUs = lazy(() => import('./pages/about'));
const BlogById = lazy(() => import('./pages/blogs/blog_by_id'));
const ProfilePage = lazy(() => import('./pages/profile'));
const BookingList = lazy(()=> import('./pages/booking'));
const SignIn = lazy(() => import('./pages/login'));
const SignUp = lazy(() => import('./pages/signup'));
const AllRetreats = lazy(() => import('./pages/our_retreat_plans/all_retreats'));
const AllBlogs = lazy(() => import('./pages/blogs/all_blogs'));
const Success = lazy(() => import('./pages/our_retreat_plans/SuccessFull'));
const Financing = lazy(()=> import('./pages/our_retreat_plans/financing'));
const Plans = lazy(()=> import('./pages/plans/index'))



function App() {
  const { checkUser, isLoggedIn } = useAuthContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const fetchCheckUser = async () => {
    // setLoading(true);
    await checkUser();
    setLoading(false);
  };

  useEffect(() => {
    fetchCheckUser();
  }, []);

  if (loading) {
    // Show loader while loading
    return (
      <div className="flex items-center justify-center w-full my-20 text-center">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          colors={["black"]}
        />
      </div>
    );
  }


  return (
    <div className="font-visby">
    {!loading ? (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="signup" element={<Suspense fallback={<ColorRing />}><SignUp /></Suspense>} />
          <Route path="login" element={<Suspense fallback={<ColorRing />}><SignIn /></Suspense>} />
          <Route path="about-us" element={<Suspense fallback={<ColorRing />}><AboutUs /></Suspense>} />
          <Route path="privacy-policy" element={<Suspense fallback={<ColorRing />}><PrivacyPolicy /></Suspense>} />
          <Route path="retreat-plans" element={<Suspense fallback={<ColorRing />}><AllRetreats /></Suspense>} />
          <Route path="retreat-plans/:id" element={<Suspense fallback={<ColorRing />}><OurRetreatPlans /></Suspense>} />
            <Route path="blogs" element={<Suspense fallback={<ColorRing />}><AllBlogs /></Suspense>} />
          <Route path="blog/:id" element={<Suspense fallback={<ColorRing />}><BlogById /></Suspense>} />
          <Route path="contact-us" element={<Suspense fallback={<ColorRing />}><Contact /></Suspense>} />
          <Route path="reset-password" element={<Suspense fallback={<ColorRing />}><ForgotPassword /></Suspense>} />
          <Route path="verify-code" element={<Suspense fallback={<ColorRing />}><VerifyCode /></Suspense>} />
          <Route path="see-plans" element={<Suspense fallback={<ColorRing />}><Plans /></Suspense>} />
          <Route path="/create-new-password" element={<Suspense fallback={<ColorRing />}><CreateNewPassword /></Suspense>} />

        </Route>
        
        {/* Routes accessible only when isLoggedIn is true */}
        <Route path='/' element={<Layout />}>
        <Route element={<PrivateRoutes />}>
          <Route path="profile" element={<Suspense fallback={<ColorRing />}><ProfilePage /></Suspense>} />
            <Route path="profile/edit" element={<Suspense fallback={<ColorRing />}><ProfilePage /></Suspense>} />
            <Route path="payment-status/success" element={<Suspense fallback={<ColorRing />}><Success /></Suspense>} />
            <Route path="booking-list" element={<Suspense fallback={<ColorRing />}><BookingList /></Suspense>} />
            <Route path="retreat-plans/:id/financing" element={<Suspense fallback={<ColorRing />}><Financing /></Suspense>} />
            <Route path="favorite-list" element={<Suspense fallback={<ColorRing />}><FavoriteEvents /></Suspense>} />

          {/* Add other protected routes here */}
        </Route>
        </Route>

        <Route path="*" element={<Suspense fallback={<ColorRing />}><NoPage /></Suspense>} />
      </Routes>
    ) : (
      <div className="flex items-center justify-center w-full my-20 text-center">
        <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["black"]}
          />
      </div>
    )}
    <ToastContainer
position="top-center"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
// transition: Bounce,
/>
  </div>
   
  );
}

export default App;
