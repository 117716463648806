import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContextProvider";
// import { useAuthContext } from "./path/to/AuthContext";

const PrivateRoutes = () => {
  const { isLoggedIn } = useAuthContext();

  // If the user is not logged in, redirect to the login page
  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
