import React from "react";
import Slider from "react-slick";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEventContext } from "../../contexts/EventContextProvider";
import { baseUrl } from "../../apis/base_url";
import { Link } from "react-router-dom";

function Blogs() {





  return (
    <div>
      <div className="md:w-[70%] w-full md:p-0 px-4 mt-16 mx-auto">
        <h1 className="text-[#2C2C2C] md:text-5xl text-3xl font-extrabold text-center mt-5">
          Blogs
        </h1>
      </div>
      <div className="md:w-[60%] w-full md:p-0 px-4   mx-auto md:overflow-x-visible overflow-x-hidden ">
        <BlogsSlider />
        <div className="flex items-center w-full justify-center mt-7">
          <Link to="/blogs">
            <button className="px-10 py-2 text-sm font-normal text-white bg-[#8DC67B] rounded-md shadow-md hover:text-white gradient-bg">
              See More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Blogs;

function BlogsSlider() {
  const NextArrow = ({ onClick }) => (
    <div
      className="arrow next md:-right-20 -right-7 top-1/2 transform -translate-y-1/2"
      onClick={onClick}
    >
      <FiChevronRight className="text-3xl" />
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div
      className="arrow prev md:-left-20 -left-7 top-1/2 transform -translate-y-1/2 "
      onClick={onClick}
    >
      <FiChevronLeft className="text-3xl" />
    </div>
  );

  var settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    aautoplay: true, // Enable auto-scrolling
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  const { blogsEvents } = useEventContext();
  return (
    <Slider {...settings}>
      {
        blogsEvents && blogsEvents["blogs"] && blogsEvents["blogs"].map((event, id) => {
          return <div className="md:px-5 px-2 py-5  md:w-[40%] w-[90%]">
            <div className="rounded-md shadow-lg text-[#505D68]">
              <div className=" relative">
                <img
                  loading="lazy"
                  className="rounded-md w-full h-[16rem] object-cover object-center"
                  src={baseUrl + "/" + event.image.path + "/" + event.image.name}
                  alt="place"
                />
                <div className="absolute top-0 left-0 w-full h-full gradient-background  rounded-md z-10"></div>
                <p className="font-bold z-20 absolute text-white bottom-5 left-5 line-clamp-2 ">
                  {event.title}
                </p>
              </div>
              <div className="md:p-5 p-3">
                {/* description */}
                <div className="mb-5  w-full line-clamp-2 ">
                  <p>
                  {event.introduction}
                  </p>
                </div>

                <div className="flex items-center justify-between ">
                  {/* profile section */}
                  <div className=" flex gap-5 items-center">
                    <h1
                      className="rounded-full w-[2.5rem] h-[2.5rem] bg-black text-white font-semibold text-center text-3xl pt-1"

                    >{event?.user?.name[0]}</h1>

                    <div>
                      <p className="opacity-70 text-sm">{event?.user?.name}</p>
                      <p className="opacity-70 text-sm">{event?.posted_at}</p>
                    </div>
                  </div>
                  {/*   learn more*/}

                  <Link to={"/blog/"+event.id} className="underline text-base cursor-pointer">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        })
      }

      {
        blogsEvents && blogsEvents["blogs"].length < 3 && blogsEvents["blogs"].map((event, id) => {
          return <div className="md:px-5 px-2 py-5  md:w-[40%] w-[90%]">
            <div className="rounded-md shadow-lg text-[#505D68]">
              <div className=" relative">
                <img
                  loading="lazy"
                  className="rounded-md w-full h-[16rem] object-cover object-center"
                  src={baseUrl + "/" + event.image.path + "/" + event.image.name}
                  alt="place"
                />
                <div className="absolute top-0 left-0 w-full h-full gradient-background  rounded-md z-10"></div>
                <p className="font-bold z-20 absolute text-white bottom-5 left-5 line-clamp-2 ">
                  {event.title}
                </p>
              </div>
              <div className="md:p-5 p-3">
                {/* description */}
                <div className="mb-5  w-full line-clamp-2 ">
                  <p>
                    {event.content}
                  </p>
                </div>

                <div className="flex items-center justify-between ">
                  {/* profile section */}
                  <div className=" flex gap-5 items-center">
                    <h1
                      className="rounded-full w-[2.5rem] h-[2.5rem] bg-black text-white font-semibold text-center text-3xl pt-1"

                    >{event?.user?.name[0]}</h1>

                    <div>
                      <p className="opacity-70 text-sm">{event?.user?.name}</p>
                      <p className="opacity-70 text-sm">{event?.posted_at}</p>
                    </div>
                  </div>
                  {/*   learn more*/}

                  <a href="#" className="underline text-base cursor-pointer">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        })
      }

{
        blogsEvents && blogsEvents["blogs"].length == 1 && blogsEvents["blogs"].map((event, id) => {
          return <div className="md:px-5 px-2 py-5  md:w-[40%] w-[90%]">
            <div className="rounded-md shadow-lg text-[#505D68]">
              <div className=" relative">
                <img
                  loading="lazy"
                  className="rounded-md w-full h-[16rem] object-cover object-center"
                  src={baseUrl + "/" + event.image.path + "/" + event.image.name}
                  alt="place"
                />
                <div className="absolute top-0 left-0 w-full h-full gradient-background  rounded-md z-10"></div>
                <p className="font-bold z-20 absolute text-white bottom-5 left-5 line-clamp-2 ">
                  {event.title}
                </p>
              </div>
              <div className="md:p-5 p-3">
                {/* description */}
                <div className="mb-5  w-full line-clamp-2 ">
                  <p>
                    {event.content}
                  </p>
                </div>

                <div className="flex items-center justify-between ">
                  {/* profile section */}
                  <div className=" flex gap-5 items-center">
                    <h1
                      className="rounded-full w-[2.5rem] h-[2.5rem] bg-black text-white font-semibold text-center text-3xl pt-1"

                    >{event?.user?.name[0]}</h1>

                    <div>
                      <p className="opacity-70 text-sm">{event?.user?.name}</p>
                      <p className="opacity-70 text-sm">{event?.posted_at}</p>
                    </div>
                  </div>
                  {/*   learn more*/}

                  <a href="#" className="underline text-base cursor-pointer">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        })
      }

    </Slider>
  );
}
