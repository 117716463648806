// LocationContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";

const LocationContext = createContext();

export const LocationContextProvider = ({ children }) => {
  const [allLocations, setAllLocations] = useState(null);

  const getLocations = async (token) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("get", "/api/v1/locations", {}, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setAllLocations(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  // const addLocation = async (token, locationData) => {
  //   try {
  //     const headers = {
  //       "Content-Type": "application/json",
  //       "X-CSRF-TOKEN": document.head
  //         .querySelector('meta[name="csrf-token"]')
  //         ?.getAttribute("content"),
  //       Authorization: `Bearer ${token}`,
  //     };

  //     const response = await ApiTemplate("post", "/api/v1/location", locationData, headers);
  //     if (response && response["success"] === true) {
  //       console.log(response["data"]);
  //       setAllLocations([...allLocations, response["data"]]);
  //       return true;
  //     } else {
  //       console.log(response["message"], response.message);
  //       return false;

  //     }
  //   } catch (error) {
  //     console.error("Error during API call:", error);
  //     return false;

  //   }
  // };

  // const deleteLocation = async (token, locationId) => {
  //   try {
  //     // Implement the logic to delete a location using your API here
  //   } catch (error) {
  //     console.error("Error during API call:", error);
  //   }
  // };

  // const updateLocation = async (token, locationId, locationData) => {
  //   try {
  //     // Implement the logic to update a location using your API here
  //   } catch (error) {
  //     console.error("Error during API call:", error);
  //   }
  // };

  return (
    <LocationContext.Provider
      value={{
        allLocations,
        getLocations,
        setAllLocations
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => {
  const locationContextValue = useContext(LocationContext);
  return locationContextValue;
};
