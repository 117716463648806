import React, {createContext, useContext, useState} from "react";
import { jsonHeader } from "../apis/header";
import ApiTemplate from "../apis/api_template";

const QuestionsContext = createContext();

export const QuestionsContextProvider = ({children}) =>{
    const [allQuestions, setAllQuestions]=useState(null);
    const [getQuestionsSuccess,setGetQuestionsSuccess]=useState(false);


    const getQuestions = async (token) => {
        console.log("Questions api call")
        try {
            const headers = jsonHeader(token)

            const response = await ApiTemplate("get", "/api/v1/search_parameter", {}, headers);
            if (response && response["success"] === true) {
                console.log(response);
                setAllQuestions(response["data"]);
                setGetQuestionsSuccess(true)
            } else {
                console.log("response :", response.message);
            }
        } catch (error) {
            console.error("Error during API call:", error);
        }
    };

    return (
        <QuestionsContext.Provider
        value={{
            allQuestions,
            getQuestions,
            getQuestionsSuccess}}
        >
            {children}
        </QuestionsContext.Provider>
    )
}

export const useQuestionsContext = () =>{
    const questionContextValue = useContext(QuestionsContext);
    return questionContextValue;
}