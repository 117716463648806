import { useEffect } from "react";
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { useEventContext } from "../../contexts/EventContextProvider";
import AboutUs from "./about_us";
import Banner from "./banner";
import Blogs from "./blogs";
import OurPlans from "./our_plans";
import WhyPeopleLoveFrom from "./people_love";

const Home = () => {
  const { userProfile } = useAuthContext();
  const { allEvents,
    getEvents } = useEventContext();

  const getAllEvents = async () => {
    await getEvents();
  }

  useEffect(() => {
    getAllEvents();
  }, []);
  useEffect(()=>{
   console.log(localStorage.getItem('favorite')); 
  })
  return (
    <div>
      <Banner />
      <OurPlans />
      <AboutUs />
      <WhyPeopleLoveFrom />
      <Blogs />
    </div>
  );
};

export default Home;
